/* eslint-disable */
import { sortBy } from "lodash"


export enum Expansion {
    CALL_OF_THE_ARCHONS = "CALL_OF_THE_ARCHONS",
    AGE_OF_ASCENSION = "AGE_OF_ASCENSION",
    WORLDS_COLLIDE = "WORLDS_COLLIDE",
    ANOMALY_EXPANSION = "ANOMALY_EXPANSION",
    MASS_MUTATION = "MASS_MUTATION",
    DARK_TIDINGS = "DARK_TIDINGS",
    WINDS_OF_EXCHANGE = "WINDS_OF_EXCHANGE",
    UNCHAINED_2022 = "UNCHAINED_2022",
    VAULT_MASTERS_2023 = "VAULT_MASTERS_2023",
    GRIM_REMINDERS = "GRIM_REMINDERS",
    MENAGERIE_2024 = "MENAGERIE_2024",
    VAULT_MASTERS_2024 = "VAULT_MASTERS_2024",
    AEMBER_SKIES = "AEMBER_SKIES",
    TOKENS_OF_CHANGE = "TOKENS_OF_CHANGE",
    MORE_MUTATION = "MORE_MUTATION",
    MARTIAN_CIVIL_WAR = "MARTIAN_CIVIL_WAR",
    DISCOVERY = "DISCOVERY",
}

export class ExpansionUtils {
    static readonly values = [Expansion.CALL_OF_THE_ARCHONS, Expansion.AGE_OF_ASCENSION, Expansion.WORLDS_COLLIDE, Expansion.ANOMALY_EXPANSION, Expansion.MASS_MUTATION, Expansion.DARK_TIDINGS, Expansion.WINDS_OF_EXCHANGE, Expansion.UNCHAINED_2022, Expansion.VAULT_MASTERS_2023, Expansion.GRIM_REMINDERS, Expansion.MENAGERIE_2024, Expansion.VAULT_MASTERS_2024, Expansion.AEMBER_SKIES, Expansion.TOKENS_OF_CHANGE, Expansion.MORE_MUTATION, Expansion.MARTIAN_CIVIL_WAR, Expansion.DISCOVERY]
    static valueOf = (value: string) => ExpansionUtils.values.find(enumValue => enumValue === value)
    static sort = (value: Expansion[]) => sortBy(value, expansion => ExpansionUtils.values.indexOf(expansion))
}